import MenuIcon from '@mui/icons-material/Menu';
import { IconButton,  Drawer, Box, Stack } from '@mui/material';
import { useState } from 'react';
import Mitgliederantrag from "..\\src\\Anmeldung2024.pdf";
import Logo from "..\\src\\DJKLOGO.png";

export default function SideNav() {

    const [navBarOpen, setNavBarOpen] = useState(false);

    function toggleNavBar() {
        setNavBarOpen(!navBarOpen)
    }

    const links = [{text: "DJK Durlach", link: "/"}, {text: "DJK Webshop", link: "https://team.jako.com/de-de/team/djk_durlach_webshop_test/"}, {text: "Mitglied werden" , link: Mitgliederantrag}, {text: "Trainingszeiten", link: "Trainingszeiten"} ]

    return (
        <>
            <IconButton onClick={toggleNavBar} style={{position: "absolute", right: "10px", top: "10px"}}>
                <MenuIcon fontSize='large' style={{color: "rgb(255,230,0 )"}} />
            </IconButton>
            <Drawer open={navBarOpen} onClose={toggleNavBar} anchor='right'>
                <Box sx={{width: 250 }} role="presentation" onClick={toggleNavBar} style={{backgroundColor: "black", height: "100%"}}>
                <img src={Logo} style={{height: "50px", paddingLeft: "35%", paddingTop: "10px", paddingBottom: "10px"}} alt='Logo'/>
                    <Stack direction="column" spacing={2} style={{padding: "5px"}}>
                        {links.map((link) => {
                            return (<>
                            <a style={{color: "rgb(255,230,0 )", textDecoration: "none", fontSize: "large", paddingLeft: "5px"}} href={link.link}>{link.text}</a></>)
                        })}
                    </Stack>
                </Box>
            </Drawer>
    </>);
}